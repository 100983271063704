import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import SwipeableViews from "react-swipeable-views";

import { resetSwipeableIndex } from "./actions";

import AuthStep from "./authStep/AuthStep";
import SelectCommandStep from "./selectedCommandStep/SelectCommandStep";

import translate from '../../i18n/translate';

const ConnexionBackground = styled(Paper)`
  max-width: 400px;
  padding: 20px 40px 20px 40px;
  @media (max-width: 600px) {
    width: 70%;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const Title = styled.div`
  margin: 1.5em;
  font-size: 1.8em;
  text-align: center;
`;

const titleStyle = {
  textAlign: "center",
  fontSize: "1.4em",
  fontWeight: "200",
  marginBottom: "30px",
};

const infoStyle = {
  fontSize: "14px",
};

/**
 * Composant de la page de login
 */

class Login extends Component {
  state = {
    redirectToReferrer: false,
  };

  componentDidMount() {
    this.props.resetSwipeableIndex();
  }
  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;
    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }
    return (
      <div>
        <Title>
          <span className="page-title">{translate("home_title")}</span>
        </Title>
        <Container>
          <ConnexionBackground className="white-box" elevation={0}>
              <div style={titleStyle}>Informations</div>
                <p style={infoStyle}>
                  {translate("home_1")}
                  <br/><br/>
                  <b>{translate("home_2")}</b>
                  <br />
                  {translate("home_3")}
                </p>
          </ConnexionBackground>
        </Container>
        <Container>
          <ConnexionBackground className="white-box" elevation={0}>
            <SwipeableViews index={this.props.swipeableIndex} disabled>
              <AuthStep
                handleAuthentificated={this.props.handleAuthentificated}
              />
              <SelectCommandStep
                handleAuthentificated={this.props.handleAuthentificated}
              />
            </SwipeableViews>
          </ConnexionBackground>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  swipeableIndex: state.login.swipeableIndex,
});

const mapDispatchToProps = (dispatch) => ({
  resetSwipeableIndex: () => {
    dispatch(resetSwipeableIndex());
  },
});

Login = reduxForm({
  form: "login",
})(Login);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
